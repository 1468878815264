<nb-card class="edit-dialog">
    <nb-card-header>
        <p class="primary-header">Upload {{ fileType }}</p>
        <button (click)="cancel()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div>
            <ngx-drop-file
                (onFileAdded)="onFileAdded($event)"
                [fileFormats]="acceptedFileTypes"
                [multiple]="false"
            ></ngx-drop-file>
            <div *ngIf="fileUploadForm.value" class="file-upload-container">
                <div *ngFor="let file of fileList.value; index as i" class="file-upload-item">
                    <div class="info-container">
                        <!-- Media is video -->
                        <video *ngIf="!mediaIsImage(file.fileFormat)" src="{{ file.url }}" class="photo"></video>

                        <!-- Media is image -->
                        <img *ngIf="mediaIsImage(file.fileFormat)" [src]="file.url" alt="" class="photo" />

                        <div class="file-info-row">
                            <div class="file-name">
                                {{ file.name }}
                            </div>
                            <div class="file-size">{{ file.size }}</div>
                        </div>
                    </div>
                    <div class="col-7">
                        <nb-progress-bar size="tiny" status="info" [value]="file.progress"></nb-progress-bar>
                    </div>
                    <button (click)="removeFile(i)" ghost nbButton>
                        <nb-icon class="icon" icon="trash-2-outline"></nb-icon>
                    </button>
                </div>
            </div>
        </div>
    </nb-card-body>

    <nb-card-footer class="btn-container full-btn">
        <button (click)="cancel()" nbButton status="basic">
            <nb-icon icon="close-circle-outline"></nb-icon>
            Cancel
        </button>

        <button (click)="save()" class="ml-3" nbButton status="primary" [disabled]="fileUploadForm.invalid">
            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
            Save
        </button>
    </nb-card-footer>
</nb-card>
