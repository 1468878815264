import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {TimeUnlocalizePipe} from '@theme/pipes';
import * as moment from 'moment';

@Component({
    template: `
        <div class="date-filter-input-wrap">
            <input
                class="form-control"
                placeholder="{{ column.title }}"
                [formControl]="inputControl"
                [nbDatepicker]="rangepicker"
                readonly
            />
            <nb-rangepicker #rangepicker></nb-rangepicker>

            <div *ngIf="inputControl.value" class="clear-date-icon">
                <nb-icon (click)="clearDateFilterInput()" icon="close" status="basic"></nb-icon>
            </div>
        </div>
    `,
    styleUrls: ['./date-filter-component.scss'],
})
export class DateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
    constructor(private unlocalizeTimePipe: TimeUnlocalizePipe) {
        super();
    }

    inputControl: FormControl = new FormControl();

    ngOnInit() {
        this.inputControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.delay))
            .subscribe((value: string) => {
                if (value == null) {
                    this.query = '';
                    this.setFilter();
                } else if (this.inputControl.status === 'VALID') {
                    const startDateRaw = moment(this.inputControl.value.start);
                    // End date is set to selected date + 1 day (i.e. 12:00am next day); includes entire end day.
                    const endDateRaw = moment(this.inputControl.value.end).add(1, 'days');

                    const startDate = this.unlocalizeTimePipe.transform(startDateRaw.toDate());
                    const endDate = this.unlocalizeTimePipe.transform(endDateRaw.toDate());
                    const result = startDate + '|' + endDate;

                    this.query = result;
                    this.setFilter();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }

    clearDateFilterInput() {
        this.inputControl.reset();
    }
}
