import {
    CustomerDemographicData,
    DataDetails,
    DistrictData,
    RoadMoratoriumData,
    SpatialEntityData,
} from '../../../../../pages/geospatial-viewer/model/api';
import {Metric, ValueType} from '../../../../../pages/geospatial-viewer/model/metric';
import {EnginDataPrepareService} from './engin-data-prepare.service';
import {CoordinateConverterService} from './coordinate-converter.service';
import {Injectable} from '@angular/core';
import {Project} from '../../../../../pages/geospatial-viewer/model/project';

@Injectable()
export class GeospatialService {
    constructor(
        private enginDataPrepareService: EnginDataPrepareService,
        private coordinateConverterService: CoordinateConverterService,
    ) {}

    // Import from renderer service?
    public getDisplayCategoryParser(valueType: ValueType, dataDetails: DataDetails) {
        if (valueType === ValueType.CATEGORY) {
            return function (rawData) {
                // name.split(" (")[0]) handles cases with labels like "Very Poor (0-25)" being mapped to "Very Poor"
                switch (rawData.split(' (')[0]) {
                    case 'Very Poor':
                    case 'High Risk':
                    case 'HI5':
                        return 'CATEGORY_5';
                    case 'Poor':
                    case 'HI4':
                        return 'CATEGORY_4';
                    case 'Fair':
                    case 'Moderate Risk':
                    case 'HI3':
                        return 'CATEGORY_3';
                    case 'Good':
                    case 'HI2':
                        return 'CATEGORY_2';
                    case 'Very Good':
                    case 'Low Risk':
                    case 'HI1':
                        return 'CATEGORY_1';
                }
                return rawData;
            };
        } else if (valueType === ValueType.NUMERIC) {
            return function (rawData) {
                const val = Number(rawData);
                const stepSize = (dataDetails.max - dataDetails.min) / 5;

                if (val < stepSize) {
                    return 'NUMERIC_1';
                } else if (val >= stepSize && val < 2 * stepSize) {
                    return 'NUMERIC_2';
                } else if (val >= 2 * stepSize && val < 3 * stepSize) {
                    return 'NUMERIC_3';
                } else if (val >= 3 * stepSize && val < 4 * stepSize) {
                    return 'NUMERIC_4';
                } else {
                    return 'NUMERIC_5';
                }
            };
        } else {
            return function (rawData) {
                return 'NUMERIC_1';
            };
        }
    }

    // Data prepare services
    public prepareEnginData(data: SpatialEntityData[], metric: Metric, dataDetails: DataDetails): SpatialEntityData[] {
        const mapColourParser = this.getDisplayCategoryParser(metric?.valueType, dataDetails);

        return this.enginDataPrepareService.prepare(data, mapColourParser);
    }

    public prepareProjectData(data: Project[]): Project[] {
        return data.map((entity: any) => {
            return {
                ...entity,
                // Spatial data
                geoJsonGeometry: entity.geoJsonGeometry ? entity.geoJsonGeometry : JSON.parse(entity.projectGeography),
            };
        });
    }

    public prepareAdditionalMapData(
        data: RoadMoratoriumData[] | DistrictData[] | CustomerDemographicData[],
    ): RoadMoratoriumData[] | DistrictData[] | CustomerDemographicData[] {
        return data.map((entity: any) => {
            return {
                ...entity,
                // Spatial data
                geoJsonGeometry: JSON.parse(entity.geoJsonGeometryStr),
            };
        });
    }
}
