<div *ngIf="cellType === FormCellType.PREVIOUS; else currentIssues"></div>
<ng-template #currentIssues>
    <div class="form-container" *ngIf="issuesLoaded.getValue()">
        <ng-container *ngIf="issueOptions.length === 0">
            <p class="margin-rem">-</p>
        </ng-container>

        <!-- buttons to activate -->
        <div class="container" style="display: flex; justify-content: space-between">
            <div class="flex-column">
                <nb-button-group multiple class="btn-group-container">
                    <button
                        class="button-style mb-1"
                        (click)="selectedOption(option)"
                        *ngFor="let option of issueOptions"
                        nbButtonToggle
                        [pressed]="option.selected"
                        [ngClass]="option.selected ? 'selected-btn' : 'unselected-btn'"
                        [disabled]="pageMode === FormMode.VIEW"
                        size="small"
                    >
                        {{ option.label }}
                    </button>
                </nb-button-group>
                <ngx-validation-message
                    label="Value"
                    [showRequired]="issuesForm?.touched && issuesForm?.hasError('required')"
                ></ngx-validation-message>
            </div>
        </div>
        <hr class="solid" />
        <div class="container" *ngFor="let issueItem of issues; let i = index">
            <!-- issue card headeer -->
            <div style="display: flow-root">
                <!-- Issue label -->
                <p class="labels" style="float: left">
                    {{ getOptionLabel(issueItem.optionId) }}
                </p>

                <!-- x button delete issue -->
                <button
                    ghost
                    status="basic"
                    nbButton
                    (click)="onDeleteIssue(i, issueItem.optionId)"
                    [disabled]="pageMode === FormMode.VIEW"
                    style="float: right; padding: 0"
                >
                    <nb-icon icon="close" status="basic"></nb-icon>
                </button>
            </div>

            <!-- checkboxes issue resolved -->
            <div style="display: flow-root">
                <nb-checkbox [checked]="issueItem.isIssue" [disabled]="pageMode === FormMode.VIEW" style="float: left">
                    Issue
                </nb-checkbox>
                <nb-checkbox
                    [checked]="issueItem.isResolved"
                    [disabled]="pageMode === FormMode.VIEW"
                    style="float: right"
                >
                    Resolved
                </nb-checkbox>
            </div>

            <!-- comment box label (required)-->
            <p class="labels">
                {{ "Comment" }}
                <span *ngIf="fieldRequired()" class="red">*</span>
            </p>

            <!-- comment box -->
            <textarea
                #textarea
                [ngClass]="{
                    'view-mode': pageMode === FormMode.VIEW,
                    'edit-mode': pageMode === FormMode.EDIT,
                    required: issuesForm?.touched && issuesForm?.hasError('required')
                }"
                nbInput
                fullWidth
                >{{ issueItem.comment }}</textarea
            >

            <!-- image box -->
            <p class="labels">
                {{ "Photo" }}
                <span *ngIf="fieldRequired()" class="red">*</span>
            </p>
            <!-- image media control -->
            <ngx-form-field-media
                [pageMode]="pageMode"
                [field]="{fieldType: mediaType, id: field.id}"
                [result]="issueItem.media"
                [required]="false"
                [viewMode]="viewMode"
                [cellType]="cellType"
                [fieldResultForm]="fieldResultForm"
                (formFieldChangeEvent)="formFieldChangeEvent.emit($event)"
                [checkValidation]="checkValidation"
                [s3service]="s3service"
            ></ngx-form-field-media>

            <!-- line spacer between card instances not on last -->
            <hr class="solid" *ngIf="i !== issues.length" />
        </div>
    </div>
</ng-template>
