import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'ngx-input-error',
    template: `
        <div
            *ngIf="
                formGroup.get(fieldName).invalid &&
                formGroup.get(fieldName).errors &&
                (formGroup.get(fieldName).dirty || formGroup.get(fieldName).touched)
            "
        >
            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('required')">
                {{ fieldName | ngxSentenceCase }} is required.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('minlength')">
                The minimum length for {{ fieldName | ngxSentenceCase }} is
                {{ formGroup.get(fieldName).errors.minlength.requiredLength }}
                characters.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('maxlength')">
                The maximum length for {{ fieldName | ngxSentenceCase }} is
                {{ formGroup.get(fieldName).errors.maxlength.requiredLength }}
                characters.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('mustMatch')">
                Confirm password does not match.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('mustNotMatch')">
                Current and new password must not be the same.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('usernameAlreadyExists')">
                Username already taken.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('unique')">
                This name already taken.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('email')">
                Entered email is not valid.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('pattern')">
                {{ patternMessage }}
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('emailAlreadyExists')">
                Email already taken.
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('invalidDateRange')">
                {{ formGroup.get(fieldName).getError('invalidDateRange') }}
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('outOfBatchRange')">
                {{ formGroup.get(fieldName).getError('outOfBatchRange') }}
            </small>

            <small class="text-danger" *ngIf="formGroup.get(fieldName).hasError('invalidDate')">
                {{ formGroup.get(fieldName).getError('invalidDate') }}
            </small>
        </div>
    `,
})
export class InputErrorComponent {
    @Input() formGroup: FormGroup;
    @Input() fieldName: string;
    //ToDo change implementation of input-error
    @Input() patternMessage: string = 'Input does not match with provided pattern.';
}
