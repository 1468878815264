import {Component, OnInit, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

export interface ConfirmDialogData {
    title?: string;
    message?: string;
    confirmText?: string;
    cancelText?: string;
    innerHTML?: boolean;
}

@Component({
    selector: 'ngx-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    private confirmData: ConfirmDialogData;

    //TODO: change the dialog according the proper NbDialogRef usage and for typization
    constructor(@Optional() private dialogRef: NbDialogRef<ConfirmDialogData>) {}

    ngOnInit() {
        this.confirmData = this.dialogRef.componentRef.instance;
    }

    confirm = () => {
        this.dialogRef.close({confirm: true});
    };

    cancel = () => {
        this.dialogRef.close({confirm: false});
    };

    get confirmationData() {
        return this.confirmData;
    }
}
