import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ApiType, S3Service} from '@core/interfaces/common/s3';
import {FormField} from '@core/interfaces/engin/data-preparation';
import {
    AssetInspectionResultDto,
    FieldType,
    FileFormat,
    FormCellType,
    FormFieldOption,
    FormViewModeType,
    IssueFieldResultDto,
    IssueMediaResultDto,
    MediaFieldResultDto,
    SelectFieldOptionResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {FormFieldBaseComponent} from '../base/form-field-base.component';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';
import {FormService} from '../../form.service';
import {first} from 'rxjs/operators';

interface Option extends SelectFieldOptionResultDto {
    label: string;
    order: number;
    selected: boolean;
}

interface Issue extends IssueFieldResultDto {
    /** used to inject into media component */
    media: MediaFieldResultDto[];
}

@Component({
    selector: 'ngx-form-field-issues',
    templateUrl: './issues.component.html',
    styleUrls: ['./issues.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuesComponent extends FormFieldBaseComponent<IssueFieldResultDto> implements OnInit {
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() s3service: S3Service;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    @Input() results: AssetInspectionResultDto;
    @Output() formFieldChangeEvent = new EventEmitter();

    ApiType = ApiType;
    public FormMode = FormMode;
    issues: Issue[] = [];
    issuesForm: any;
    issueOptions: Option[] = [];
    issuesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    mediaType = FieldType.IMAGE_VIDEO;

    constructor(private fb: FormBuilder, public formService: FormService, private cd: ChangeDetectorRef) {
        super();
    }
    ngOnInit(): void {
        this.issuesLoaded.next(false);
        this.issuesForm = this.fieldResultForm.get(this.field.id + '');

        this.configureIssues();
    }

    private configureIssues() {
        let promises = [];
        Object.values(this.issuesForm.value).forEach((obj: any) => {
            const issue: any = obj;
            // make an array of promises to call out and get urls
            Object.values(issue.mediaList).forEach((mediaItem: any) => {
                promises.push(this.getMediaItemUrl(mediaItem.fileName, mediaItem.fileFormat));
            });

            this.issues.push({
                comment: issue.comment,
                fieldId: issue.fieldId,
                isIssue: issue.isIssue,
                isResolved: issue.isResolved,
                mediaList: issue.mediaList,
                optionId: issue.optionId,
                media: [],
            } as Issue);
        });

        if (promises.length > 0) {
            this.getUrlsFromPromises(promises);
        } else {
            this.configureIssueOptions();

            this.issuesLoaded.next(true);
            this.cd.detectChanges(); // notify the form to update with changes to model
        }
    }

    /** gets urls for all issue.mediaList items and posts results to issue.media array */
    getUrlsFromPromises(promises: any[]): void {
        forkJoin(promises).subscribe((responses: any[]) => {
            responses.forEach((res: any) => {
                // get the issue that needs update
                const issue = this.issues.find((i) => i.mediaList.find((m) => m.fileName === res.response.fileName));

                // update the media property with value containing url
                if (issue) {
                    issue.media.push({
                        fieldId: issue.fieldId,
                        fileKey: res.response.fileKey,
                        fileName: res.response.fileName,
                        fileFormat: res.response.fileFormat,
                        fileSize: issue.mediaList.find((m) => m.fileName == res.response.fileName).fileSize,
                        url: res.response.url,
                    } as MediaFieldResultDto);
                }
            });
            this.configureIssueOptions();

            this.issuesLoaded.next(true);
            this.cd.detectChanges(); // notify the form to update with changes to model
        });
    }

    /** Call out to the S3 service to get the url of the image */
    getMediaItemUrl(fileName: string, fileFormat: FileFormat): Promise<APIResponse<PresignedURL>> {
        return new Promise<APIResponse<PresignedURL>>((resolve, reject) => {
            this.s3service
                .getPresignedUrl(fileName, fileFormat, ApiType.MAINTENANCE_PLANNING)
                .pipe(first())
                .subscribe(
                    (url) => {
                        resolve(url);
                    },
                    (error) => {
                        reject('error: could not get url');
                    },
                );
        });
    }

    private configureIssueOptions(): void {
        this.issueOptions = this.field.options.map((option: FormFieldOption) => {
            return {
                fieldId: this.field.id,
                optionType: this.field.options[0].optionType,
                optionId: option.id,
                label: option.optionLabel,
                order: option.order,
                selected: !(this.issues?.find((o) => o.optionId == option.id) == undefined),
            };
        });
    }

    public selectedOption(pressedOption: Option): void {
        pressedOption.selected = !pressedOption.selected;
        const newValue: IssueFieldResultDto = this.applyValueChange(pressedOption);
        this.fieldForm?.markAsTouched();
        if (this.required && newValue) this.fieldForm?.setErrors({required: true});

        if (this.validate(newValue)) {
            this.fieldForm.setErrors(null);
        } else {
            this.fieldForm.setErrors({required: true});
            this.cd.detectChanges();
        }
        this.onIssueChanged(newValue);
    }

    protected validate(value: IssueFieldResultDto): boolean {
        if (this.required && value == null) {
            return false;
        }

        return true;
    }

    onAddIssue(optionId: number): void {
        this.issues.push({
            comment: '',
            fieldId: this.field.id,
            isIssue: false,
            isResolved: false,
            mediaList: [],
            optionId: optionId,
            media: [],
        } as Issue);

        this.cd.detectChanges();
    }

    onDeleteIssue(index: number, optionId: number): void {
        this.issues.splice(index, 1);
        this.issueOptions.find((o) => o.optionId == optionId).selected = false;
        this.cd.detectChanges();
    }

    onIssueChanged(
        newIssue?: IssueFieldResultDto,
        fieldId?: number,
        optionId?: number,
        isIssue?: boolean,
        isResolved?: boolean,
        comment?: string,
        mediaList?: IssueMediaResultDto[],
    ): void {
        let issueField: IssueFieldResultDto = null;
        let updateExisting = false;
        if (newIssue) {
            issueField = newIssue;
        } else if (fieldId && optionId && isIssue && isResolved && comment && mediaList) {
            issueField = {
                fieldId,
                optionId,
                isIssue,
                isResolved,
                comment,
                mediaList,
            } as IssueFieldResultDto;
            updateExisting = true;
        }

        if (issueField) {
            this.issues.push({
                ...issueField,
                ...{media: []},
            } as Issue);
            this.cd.detectChanges();
            this.formFieldChangeEvent.emit({
                fieldType: this.field.fieldType,
                fieldId: updateExisting ? this.field.id : null,
                value: issueField,
            });
        }
    }

    getFormValue() {
        return this.issuesForm.value;
    }

    getOptionLabel(optionId: number): string {
        const option = this.field.options.find((x) => x.id == optionId);
        if (option) {
            return option.optionLabel;
        }
        return '';
    }

    protected applyValueChange(option: Option): IssueFieldResultDto {
        if (option == null) return null;
        return {
            fieldId: this.field.id,
            optionId: option.optionId,
            isIssue: false,
            isResolved: false,
            comment: '',
            mediaList: [],
        } as IssueFieldResultDto;
    }

    protected get fieldForm(): FormControl | FormArray {
        return this.fieldResultForm?.get(this.field.id + '') as FormArray;
    }

    public getFieldRequired(field: FormField): boolean {
        return this.formService.requiredCheck(this.field, null);
    }

    public getValueOrDefault(defaultValue: string): string | number {
        return this.currentValue ? this.currentValue.comment : defaultValue;
    }
}
