import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {NbToastrService} from '@nebular/theme';
import {FileStatus} from '@core/interfaces/engin/data-preparation';

@Component({
    selector: 'ngx-drop-file',
    templateUrl: './drop-file.component.html',
    styleUrls: ['./drop-file.component.scss'],
})
export class DropFileComponent implements OnInit {
    @Input() fileFormats: string[] = [];
    @Input() multiple: boolean = true;
    @Input() additionalText: string;
    @Input() disabled?: boolean = false;
    @Output() onFileAdded: EventEmitter<any> = new EventEmitter<any>();

    formatsString = '';
    acceptFileTypes = '';

    constructor(private toastrService: NbToastrService) {}

    ngOnInit(): void {
        this.formatsString = this.fileFormats.reduce((acc, curr) => {
            if (acc !== '') acc += ', ';
            return (acc += curr);
        }, '');

        this.acceptFileTypes = this.fileFormats.reduce((acc, curr) => {
            if (acc !== '') acc += ', ';
            return (acc += `.${curr.toLowerCase()}, .${curr.toUpperCase()}`);
        }, '');
    }

    dropFile(files: NgxFileDropEntry[]) {
        files.forEach((droppedFile: NgxFileDropEntry) => {
            const entries = droppedFile.fileEntry as FileSystemFileEntry;
            const fileFormat = this.getFileFormat(entries?.name);

            if (entries.isFile && this.fileFormats.includes(fileFormat.toLowerCase())) {
                entries.file((file: File) => {
                    this.onFileAdded.emit({
                        file,
                        name: droppedFile.fileEntry.name,
                        fileFormat: this.transformFileFormat(fileFormat),
                        progress: 0,
                        status: FileStatus.STARTED,
                        size: file.size,
                        fileType: '',
                    });
                });
            } else {
                this.toastrService.warning(`Only ${this.formatsString} files are allowed.`, 'Invalid file type', {
                    duration: 0,
                });
            }
        });
    }

    getFileFormat(filename: string): string {
        return filename.substring(filename.lastIndexOf('.') + 1);
    }

    transformFileFormat(fileFormat: string): string {
        switch (fileFormat.toLowerCase()) {
            case 'csv':
                return 'CSV';
            case 'xlsx':
                return 'MS_EXCEL';
            case 'png':
                return 'PNG';
            case 'jpg':
                return 'JPG';
            case 'jpeg':
                return 'JPEG';
            case 'pdf':
                return 'PDF';
            case 'mp4':
                return 'MP4';
            case 'mov':
                return 'MOV';
            case 'png':
                return 'PNG';
            default:
                return 'INVALID';
        }
    }
}
