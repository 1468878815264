import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    FeederReviewDto,
    FeederReviewLPService,
    LpBatchDto,
} from '@core/interfaces/engin/load-profile-analysis/feeder-review';
import {filter, map, share, shareReplay, tap} from 'rxjs/operators';
import {PagingResponse} from '@core/interfaces/system/system-common';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ConfigLoadProfileRendering, ConfigurationService} from '@core/interfaces/common/configuration';
@Injectable()
export class LoadProfileStore extends Unsubscribable {
    public selectedFeeders$: BehaviorSubject<FeederReviewDto[]> = new BehaviorSubject([]);

    public batchesList: BehaviorSubject<LpBatchDto[]> = new BehaviorSubject([]);
    public batchesList$: Observable<LpBatchDto[]> = this.batchesList.asObservable();

    protected selectedBatch: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    readonly selectedBatch$: Observable<number> = this.selectedBatch.asObservable().pipe(
        filter((batchNumber: number) => !!batchNumber),
        share(),
        shareReplay(1),
    );

    public allFeedersApproved: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public frequency: BehaviorSubject<ConfigLoadProfileRendering> = new BehaviorSubject(null);
    public frequency$: Observable<ConfigLoadProfileRendering> = this.frequency.asObservable();

    constructor(private feederLpService: FeederReviewLPService, private configService: ConfigurationService) {
        super();

        this.feederLpService
            .getBatchesList()
            .pipe(
                map((res: PagingResponse<LpBatchDto>) => res.items),
                tap((list: LpBatchDto[]) => {
                    this.batchesList.next(list);

                    this.setBatch(list?.length ? list[0]?.batchNumber : null);
                }),
                share(),
            )
            .subscribe();

        this.configService
            .getConfigLoadProfileRendering()
            .subscribe((frequency: ConfigLoadProfileRendering) => this.frequency.next(frequency));
    }

    getBatch() {
        return this.selectedBatch.value;
    }

    setBatch(value: number | null) {
        return this.selectedBatch.next(value);
    }

    getBatchFullDto() {
        return this.batchesList.value.find((item) => item.batchNumber === this.getBatch());
    }

    getFrequency(): ConfigLoadProfileRendering {
        return this.frequency.value;
    }
}
