import {Injectable} from '@angular/core';
import {FeederReviewLPApi} from '@core/backend/engin/api/load-profile-analysis/feeder-review.api';
import {
    AssignMeterParentRequest,
    ChangeHistoryDto,
    FeederApprovalDto,
    FeederApproveRequest,
    FeederDeleteRequest,
    FeederMetadata,
    FeederRenameRequest,
    FeederReviewLPService,
    FeederReviewSummaryDto,
    LpBatchDto,
    LpFeederReviewDto,
} from '@core/interfaces/engin/load-profile-analysis/feeder-review';
import {Observable} from 'rxjs';
import {PagingResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

@Injectable()
export class FeederReviewLPServiceImpl extends FeederReviewLPService {
    constructor(private api: FeederReviewLPApi) {
        super();
    }

    public getBatchesList(): Observable<PagingResponse<LpBatchDto>> {
        return this.api.getBatchesList();
    }

    public getFeedersToReviewList(batchNumber: number): Observable<DataSource> {
        return this.api.getFeedersToReviewList(batchNumber);
    }

    public downloadFeedersToReviewList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl> {
        return this.api.downloadFeedersToReviewList(batchNumber, paramsString);
    }

    public getFeedersSummary(batchNumber: number): Observable<FeederReviewSummaryDto> {
        return this.api.getFeedersSummary(batchNumber);
    }

    public getFeederChangeHistory(batchNumber: number, feederId: string): Observable<ChangeHistoryDto[]> {
        return this.api.getFeederChangeHistory(batchNumber, feederId);
    }

    public getFeederMetadataByBatch(feederId: string): Observable<FeederMetadata> {
        return this.api.getFeederMetadataByBatch(feederId);
    }

    public setReviewer(batchNumber: number, data: FeederApprovalDto): Observable<LpFeederReviewDto> {
        return this.api.setReviewer(batchNumber, data);
    }

    public changeFeederMetersByBatch(batchNumber: number, data: AssignMeterParentRequest): Observable<any> {
        return this.api.changeFeederMetersByBatch(batchNumber, data);
    }

    public deleteFeeders(batchNumber: number, data: FeederDeleteRequest): Observable<any> {
        return this.api.deleteFeeders(batchNumber, data);
    }

    public renameFeeder(feederId: string, data: FeederRenameRequest): Observable<any> {
        return this.api.renameFeeder(feederId, data);
    }

    public processEntireBatch(batchNumber: number): Observable<DownloadURL> {
        return this.api.processEntireBatch(batchNumber);
    }

    public approveFeeder(batchNumber: number, feederId: string, data: FeederApproveRequest): Observable<any> {
        return this.api.approveFeeder(batchNumber, feederId, data);
    }

    public correctFeederByBatch(batchNumber: number, feederId: string, data: FeederApproveRequest): Observable<any> {
        return this.api.correctFeederByBatch(batchNumber, feederId, data);
    }
}
