import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    FeederOutlierCreate,
    FeederOutlierDto,
    FeederOutlierEdit,
    LpFeederOutlierService,
} from '@core/interfaces/engin/load-profile-analysis/feeder-outlier';
import {LpFeederOutlierApi} from '@core/backend/engin/api/load-profile-analysis/feeder-outlier.api';
import {PagingResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class LpFeederOutlierServiceImpl extends LpFeederOutlierService {
    constructor(private api: LpFeederOutlierApi) {
        super();
    }

    public getFeederOutliersList(batchNumber: number, feederId: string): Observable<DataSource> {
        return this.api.getFeederOutliersList(batchNumber, feederId);
    }

    public listFeederOutliersDto(
        batchNumber: number,
        feederId: string,
        pageSize: number = 100,
    ): Observable<PagingResponse<FeederOutlierDto>> {
        return this.api.listFeederOutliersDto(batchNumber, feederId, pageSize);
    }

    public downloadFeederOutliersList(
        batchNumber: number,
        feederId: string,
        paramsString?: string,
    ): Observable<DownloadCSVUrl> {
        return this.api.downloadFeederOutliersList(batchNumber, feederId, paramsString);
    }

    public createOutlierByBatch(batchNumber: number, data: FeederOutlierCreate): Observable<any> {
        return this.api.createOutlierByBatch(batchNumber, data);
    }

    public editOutlierByBatch(batchNumber: number, data: FeederOutlierEdit): Observable<any> {
        return this.api.editOutlierByBatch(batchNumber, data);
    }

    public deleteOutliersByBatch(batchNumber: number, outlierIds: number[]): Observable<any> {
        return this.api.deleteOutliersByBatch(batchNumber, outlierIds);
    }
}
