import {Observable} from 'rxjs';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    GenericListRequest,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {GeospatialResponse} from '../../pages/geospatial-viewer/model/api';
import {VisualizationType} from '../../pages/geospatial-viewer/model/visualization';
import {GeospatialCenterCoordinate} from '../../pages/geospatial-viewer/model/viewer-config';
import {AssetColumn, AssetDto, CoordinateLatLong} from '@core/interfaces/common/asset';

export interface AssetRegistryListRequest extends GenericListRequest {
    columns: string[];
}

export interface AssetChangeHistory {
    changeDate: string;
    changes: string;
    changeId: number;
}

export interface AssetVersionComparison {
    columns: AssetColumnVersionComparison[];
    data: AssetDto[];
}

export interface AssetColumnVersionComparison {
    fieldKey: string;
    fieldName: string;
    changed: boolean;
}

export interface RegistryUpdateRequest {
    changedAttributes: {};
}

export interface GeospatialAssetRequest {
    visualizationType: VisualizationType; //INDIVIDUAL
    filterList: Filter[];
    centerConfig: GeospatialCenterCoordinate;
}

export abstract class AssetRegistryService {
    // Single-asset CRUD operations
    abstract getAsset(assetId: string): Observable<APIResponse<AssetDto>>;

    abstract updateAsset(assetId: string, req: RegistryUpdateRequest): Observable<APIResponse<AssetDto>>;

    abstract deleteAsset(assetId: string): Observable<APIResponse<any>>;

    // Pagination
    abstract getList(req: AssetRegistryListRequest): Observable<DataSource>;

    abstract exportList(columns?: any[], filterParams?: Filter[]): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getAssetCount(filters: Filter[]): Observable<number>;

    // Advanced asset operations
    abstract getChangeHistory(assetId: string): Observable<APIResponse<AssetChangeHistory[]>>;

    abstract getCompareAssetVersion(assetId: string, changeId: number): Observable<APIResponse<AssetVersionComparison>>;

    // Supporting APIs
    abstract searchAssetId(search: string): Observable<APIResponse<string[]>>;

    abstract getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>>;

    abstract getFilterFieldOptions(body: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>>;

    abstract getAvailableColumns(): Observable<APIResponse<AssetColumn[]>>;

    // Spatial
    abstract getGeospatialView(geospatialRequest: GeospatialAssetRequest): Observable<APIResponse<GeospatialResponse>>;

    abstract getAssetCoordinate(assetId: string): Observable<APIResponse<CoordinateLatLong>>;
}
