import {ModuleWithProviders, NgModule} from '@angular/core';
import {SidebarStore} from './config/sidebar.store';
import {PagesStore} from './config/pages.store';
import {AnalyzerStore} from './analyzer/analyzer.store';
import {StudiesStore} from './common/studies.store';
import {AssetDetailsStore} from './analyzer/asset-details.store';
import {OptimizerStore} from './optimizer/optimizer.store';
import {OptimizerPopoutStore} from './optimizer/optimizer-popout.store';
import {ForecasterPopoutStore} from './forecaster/forecaster-popout-store';
import {ReportsStore} from './common/reports.store';
import {RolesStore} from './common/roles.store';
import {PlannerFilterStore} from './planner-filter.store';
import {ForecasterStore} from './forecaster/forecaster.store';
import {UsersStore} from './common/users.store';
import {AssetDataAuditStore} from './data-audit/asset-data-audit.store';
import {DataAuditPopoutStore} from './data-audit/data-audit-popout.store';
import {SupportStore} from './common/support.store';
import {DataManagementStore} from './common/data-management.store';
import {ConfigurationStore} from './config/configuration.store';
import {EvaluationDataAuditStore} from './data-audit/evaluation-data-audit.store';
import {TenantSettingsStore} from '@store/common/tenant-settings.store';
import {ProgramManagementStore} from '@store/program-management/program-management.store';
import {LiveSearchStore} from '@store/live/live-search.store';
import {HeaderFiltersStore} from '@store/load-forecast/header-filters.store';
import {SensitivityAnalysisStore} from '@store/load-forecast/sensitivity-analysis.store';
import {ProgramManagementSensitivityStore} from '@store/program-management/program-management-sensitivity.store';
import {AnalyzerControlStore} from '@store/analyzer/analyzer-control.store';
import {DocumentsStore} from '@store/common/documents.store';
import {AssetRegistryStore} from '@store/asset-registry/asset-registry.store';
import {OutcomesStore} from './program-management/outcomes.store';
import {NotesStore} from '@store/common/notes.store';
import {SurvivalAnalysisStore} from '@store/survival-analysis/survival-analysis.store';
import {MobileAdminStore} from '@store/mobile-admin/mobile-admin.store';
import {LoadProfileStore} from '@store/load-profile-analysis/load-profile-analysis.store';
import {MaintenancePlanningStore} from '@store/maintenance-planning/maintenace-planning.store';

const STORES = [
    // Common
    DataManagementStore,
    ReportsStore,
    RolesStore,
    StudiesStore,
    UsersStore,
    DocumentsStore,
    SupportStore,
    TenantSettingsStore,
    LiveSearchStore,
    NotesStore,

    // Config
    PagesStore,
    SidebarStore,
    ConfigurationStore,

    // Analyzer
    AnalyzerControlStore,
    AnalyzerStore,
    AssetDetailsStore,

    // Optimizer
    OptimizerPopoutStore,
    OptimizerStore,

    // Forecaster
    ForecasterPopoutStore,
    ForecasterStore,

    // Planner
    PlannerFilterStore,

    // Data audit
    AssetDataAuditStore,
    DataAuditPopoutStore,
    EvaluationDataAuditStore,

    // Program Management
    ProgramManagementStore,
    ProgramManagementSensitivityStore,
    HeaderFiltersStore,
    SensitivityAnalysisStore,
    OutcomesStore,

    // Asset Registry
    AssetRegistryStore,

    // Maintenance Planning
    MaintenancePlanningStore,

    // SurvivalAnalysis
    SurvivalAnalysisStore,

    // MobileAdmin
    MobileAdminStore,

    //LoadProfile
    LoadProfileStore,
];

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [...STORES],
})
export class StoreModule {
    static forRoot(): ModuleWithProviders<StoreModule> {
        return {
            ngModule: StoreModule,
            providers: [],
        };
    }
}
