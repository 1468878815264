import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {UploadMediaDialogComponent} from '@theme/components/dialogs/upload-images-dialog/upload-media-dialog.component';
import {filter, finalize, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {
    MediaCarouselDialogComponent,
    MediaEntity,
} from '@theme/components/image-carousel/image-carousel-dialog/media-carousel-dialog.component';
import {
    FieldType,
    FileFormat,
    FormCellType,
    FormField,
    FormViewModeType,
    MediaFieldResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {HelpersService} from '@core/utils/helpers.service';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiType, S3Service} from '@core/interfaces/common/s3';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';

@Component({
    selector: 'ngx-form-field-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaComponent extends FormFieldBaseComponent<MediaFieldResultDto[]> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() s3service: S3Service;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    public FormMode = FormMode;

    fileUploadForm: FormGroup = this.fb.group({
        fileList: this.fb.array([]),
    });
    mediaLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /* Enabled media types */
    enableImage: boolean = true;
    enableVideo: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: NbDialogService,
        private helpersService: HelpersService,
    ) {
        super();
    }

    get fileList() {
        return this.fileUploadForm.get('fileList') as FormArray;
    }

    ngOnInit(): void {
        this.mediaLoaded.next(false);
        this.createMediaList(this.result);

        this.enableImage = this.field.fieldType === FieldType.IMAGE || this.field.fieldType === FieldType.IMAGE_VIDEO;
        this.enableVideo = this.field.fieldType === FieldType.VIDEO || this.field.fieldType === FieldType.IMAGE_VIDEO;

        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value) => !!value),
                map((_) => {
                    const validImeArr = this.fileList.value.filter((item) => item.url);
                    if (validImeArr.length === 0 && this.required) {
                        this.fieldForm?.setErrors({required: true});
                    }
                    this.cd.detectChanges();
                }),
            )
            .subscribe();
    }

    private createMediaList(result: MediaFieldResultDto[]): void {
        if (result == null) return;
        result.map((item: MediaFieldResultDto) => {
            this.fileList.push(this.createItem(item));
        });
        this.mediaLoaded.next(true);
    }

    private createItem(result: MediaFieldResultDto): FormGroup {
        const media: MediaFieldResultDto = {
            fieldId: this.field.id,
            fileKey: result.fileKey,
            fileName: result.fileName,
            fileFormat: result.fileFormat,
            fileSize: result.fileSize,
            formattedSize: this.helpersService.formatFileSize(result.fileSize),
            url: result.url,
        };
        return this.fb.group(media);
    }

    public openCarousel(media: MediaFieldResultDto): void {
        this.dialogService
            .open(MediaCarouselDialogComponent, {
                closeOnBackdropClick: false,
                context: {
                    mediaList: this.fileList.value,
                    mainMediaName: media.fileName,
                    enableImage: this.enableImage,
                    enableVideo: this.enableVideo,
                },
            })
            .onClose.pipe(
                filter((res) => !!res),
                map(() => {}),
            )
            .subscribe(() => {});
    }

    public remove(media: MediaFieldResultDto): void {
        const index = this.fileList.controls.findIndex((control) => control.value === media);
        this.fileList.removeAt(index);

        this.triggerChange();
    }

    public addContents(): void {
        this.dialogService
            .open(UploadMediaDialogComponent, {
                closeOnBackdropClick: false,
                context: {
                    enableImage: this.enableImage,
                    enableVideo: this.enableVideo,
                    fieldId: this.field.id,
                    s3service: this.s3service,
                },
            })
            .onClose.pipe(
                filter((res) => !!res),
                map((res) => {
                    res.contents.forEach((item: MediaFieldResultDto) => {
                        this.fileList.push(this.fb.group(item));
                    });
                }),
                finalize(() => this.triggerChange()),
            )
            .subscribe();
    }

    // This component does not trigger fieldForm.valueChanges so perform this manually
    private triggerChange(): void {
        const validImeArr = this.fileList.value.filter((item) => item.url);
        const newValue: MediaFieldResultDto[] = this.applyValueChange(validImeArr);
        this.fieldForm?.markAsTouched();
        if (this.required && newValue.length === 0) this.fieldForm?.setErrors({required: true});
        if (this.validate(newValue)) {
            this.fieldForm.setErrors(null);
        } else {
            this.fieldForm.setErrors({required: true});
            this.cd.detectChanges();
        }
        this.emitEvent(newValue);
    }

    /*
     * Implement abstract methods
     */
    validate(value: MediaFieldResultDto[]): boolean {
        // base case required, no value, insta-fail
        if (this.required && (value == null || value.length == 0)) {
            return false;
        }

        // try validation rules
        const dict: Record<string, number> = {};
        this.fileList.value
            .map((file) => file.fileFormat)
            .forEach((extension) => {
                dict[extension] ? dict[extension]++ : (dict[extension] = 1);
            });

        let totalVideos = 0;
        this.videoFormats.forEach((videoExtension) => {
            dict[videoExtension] ? (totalVideos += dict[videoExtension]) : 0;
        });

        let totalImages = 0;
        this.imageFormats.forEach((imageExtension) => {
            dict[imageExtension] ? (totalImages += dict[imageExtension]) : 0;
        });

        // max 15 images no video
        if (totalImages > 15) {
            return false;
        }

        // mix of images (10) and video (5) out of range
        if (totalImages > 10 && totalVideos > 5) {
            return false;
        }

        // allowable cases within range
        if ((totalImages <= 10 && totalVideos <= 5) || (totalImages <= 15 && totalVideos === 0)) {
            return true;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormArray;
    }

    applyValueChange(items: MediaFieldResultDto[]): MediaFieldResultDto[] {
        if (items == null) return null;
        return items as MediaFieldResultDto[];
    }

    getFormValue(): any {
        return this.fileList.value;
    }

    handleImageError(media: MediaFieldResultDto) {
        /* do nothing */
    }

    imageFormats = [FileFormat.JPG, FileFormat.JPEG, FileFormat.PNG];
    videoFormats = [FileFormat.MP4, FileFormat.MOV];
    public mediaIsImage(media: MediaEntity): boolean {
        return this.imageFormats.includes(media.fileFormat);
    }
    public mediaIsVideo(media: MediaEntity): boolean {
        return this.videoFormats.includes(media.fileFormat);
    }
}
