import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse, PagingResponse} from '@core/interfaces/system/system-common';
import {
    AssignMeterParentRequest,
    FeederApprovalDto,
    FeederReviewSummaryDto,
    LpBatchDto,
    LpFeederReviewDto,
    FeederApproveRequest,
    FeederDeleteRequest,
    FeederRenameRequest,
    ChangeHistoryDto,
    FeederMetadata,
} from '@core/interfaces/engin/load-profile-analysis/feeder-review';
import {map} from 'rxjs/operators';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

@Injectable()
export class FeederReviewLPApi {
    private readonly prefix = 'load-profile-analysis';
    private readonly prefixFeederReview = 'load-profile-analysis/review/feeders';

    constructor(private api: HttpService) {}

    public getBatchesList(): Observable<PagingResponse<LpBatchDto>> {
        return this.api
            .get(`${this.prefix}/list/batches`)
            .pipe(map((res: APIResponse<PagingResponse<LpBatchDto>>) => res.response));
    }

    public getFeedersToReviewList(batchNumber: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefixFeederReview}/${batchNumber}/feeders-list`);
    }

    public downloadFeedersToReviewList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl> {
        return this.api
            .get(`${this.prefixFeederReview}/${batchNumber}/feeders-list/csv${paramsString && '?' + paramsString}`)
            .pipe(map((res: APIResponse<DownloadCSVUrl>) => res.response));
    }

    public getFeedersSummary(batchNumber: number): Observable<FeederReviewSummaryDto> {
        return this.api
            .get(`${this.prefixFeederReview}/${batchNumber}/summary`)
            .pipe(map((res: APIResponse<FeederReviewSummaryDto>) => res.response));
    }

    public getFeederChangeHistory(batchNumber: number, feederId: string): Observable<ChangeHistoryDto[]> {
        return this.api
            .get(`${this.prefixFeederReview}/${batchNumber}/${feederId}/change-history`)
            .pipe(map((res: APIResponse<ChangeHistoryDto[]>) => res.response));
    }

    public getFeederMetadataByBatch(feederId: string): Observable<FeederMetadata> {
        return this.api
            .get(`${this.prefixFeederReview}/${feederId}/metadata`)
            .pipe(map((res: APIResponse<FeederMetadata>) => res.response));
    }

    public setReviewer(batchNumber: number, data: FeederApprovalDto): Observable<LpFeederReviewDto> {
        return this.api
            .put(`${this.prefixFeederReview}/${batchNumber}/set-reviewer`, data)
            .pipe(map((res: APIResponse<LpFeederReviewDto>) => res.response));
    }

    public changeFeederMetersByBatch(batchNumber: number, data: AssignMeterParentRequest): Observable<any> {
        return this.api.post(`${this.prefixFeederReview}/${batchNumber}/change-meters`, data);
    }

    public deleteFeeders(batchNumber: number, body: FeederDeleteRequest): Observable<any> {
        return this.api.delete(`${this.prefixFeederReview}/${batchNumber}/delete-feeders`, {
            body,
        });
    }

    public renameFeeder(feederId: string, data: FeederRenameRequest): Observable<any> {
        return this.api.put(`${this.prefixFeederReview}/${feederId}/rename-feeder`, data);
    }

    public processEntireBatch(batchNumber: number): Observable<DownloadURL> {
        return this.api.get(`${this.prefixFeederReview}/${batchNumber}/process-batch`).pipe(
            map((res: APIResponse<string>) => ({
                url: res.response,
            })),
        );
    }

    public approveFeeder(batchNumber: number, feederId: string, data: FeederApproveRequest): Observable<any> {
        return this.api
            .put(`${this.prefixFeederReview}/${batchNumber}/${feederId}/submit/approve`, data)
            .pipe(map((res: APIResponse<any>) => res.response));
    }

    public correctFeederByBatch(batchNumber: number, feederId: string, data: FeederApproveRequest): Observable<any> {
        return this.api
            .put(`${this.prefixFeederReview}/${batchNumber}/${feederId}/submit/correction`, data)
            .pipe(map((res: APIResponse<any>) => res.response));
    }
}
