import {FilterFieldType} from '@core/interfaces/system/system-common';

// Columns unknown
export interface AssetDto {
    // Fixed asset properties
    assetId: string;
    assetClassCode: string;

    // Dynamic set of any asset properties
    [key: string]: any;
}

export interface AssetColumn {
    fieldKey: string;
    fieldName?: string;
    selected?: boolean;
    required?: boolean;
    fieldType?: FilterFieldType;
    disabled?: boolean;
    charLimit?: number; //only for string types
}

export interface CoordinateLatLong {
    latitude: number;
    longitude: number;
}

export const hideFields = ['fromDate', 'toDate', 'id', 'coordinate_x', 'coordinate_y'];
export const disabledFields = ['assetId', 'assetClassCode', 'id', 'location'];

export const geographicFields = [
    'coordinateSystem',
    'coordinateType',
    'coordinateX',
    'coordinateY',
    'coordinateZone',
    'geographicGroup',
    'location',
    'link',
];
