import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    ConfigurationService,
    DatasetItem,
    TenantUnitsConfig,
    WorkflowConfig,
    AssetClassConfig,
    EnginDatatableDetails,
    ConfigLoadProfileRendering,
} from '@core/interfaces/common/configuration';
import {ConfigurationApi} from '../api/configuration.api';

@Injectable()
export class ConfigurationServiceImpl extends ConfigurationService {
    constructor(private api: ConfigurationApi) {
        super();
    }

    getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>> {
        return this.api.getWorkFlows();
    }

    getDatasets(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.getDatasets();
    }

    getTablesFromSchema(schemaName: string): Observable<APIResponse<EnginDatatableDetails[]>> {
        return this.api.getTablesFromSchema(schemaName);
    }

    getAssetClassCodes(): Observable<APIResponse<AssetClassConfig[]>> {
        return this.api.getAssetClassCodes();
    }

    getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>> {
        return this.api.getTenantUnits();
    }

    getPersistedDatasetList(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.getPersistedDatasetList();
    }

    getPersistedTablesFromDataset(schemaName: string): Observable<APIResponse<EnginDatatableDetails[]>> {
        return this.api.getPersistedTablesFromDataset(schemaName);
    }

    getPersistedDatasetTableAllList(): Observable<APIResponse<EnginDatatableDetails[]>> {
        return this.api.getPersistedDatasetTableAllList();
    }

    getConfigLoadProfileRendering(): Observable<ConfigLoadProfileRendering> {
        return this.api.getConfigLoadProfileRendering();
    }
}
