import {Observable} from 'rxjs';
import {SimpleMultiSeries, SimpleUnit} from '@core/interfaces/system/system-common';

export interface InfrastructurePeakLoads {
    data: PeakLoadMetric[];
    loadUnit: SimpleUnit;
    powerFactorUnit: SimpleUnit;
}

export interface PeakLoadMetric {
    label: string;
    load: number;
    powerFactor: number;
}

export interface FeederMetricRequest {
    feederId: string;
    analysisPeriodType: LoadProfileAnalysisPeriodType;
}

export enum LoadProfileAnalysisPeriodType {
    ANNUAL = 'ANNUAL',
    QUARTERLY = 'QUARTERLY',
    MONTHLY = 'MONTHLY',
    SEASONAL = 'SEASONAL',
}

export enum LoadMetricType {
    PEAK = 'PEAK',
    PEAK_99 = 'PEAK_99',
    PEAK_95 = 'PEAK_95',
    PEAK_90 = 'PEAK_90',
    MIN_NON_ZERO = 'MIN_NON_ZERO',
}

export const LoadMetricTypeLabels = {
    [LoadMetricType.PEAK]: 'Absolute peak',
    [LoadMetricType.PEAK_99]: '99th percentile peak',
    [LoadMetricType.PEAK_95]: '95th percentile peak',
    [LoadMetricType.PEAK_90]: '90th percentile peak',
    [LoadMetricType.MIN_NON_ZERO]: 'Minimum non-zero load',
};

export abstract class FeederAnalysisLPService {
    abstract getPeekData(batchNumber: number, feederId: string): Observable<InfrastructurePeakLoads>;
    abstract getFeederMetricsByBatch(
        batchNumber: number,
        data: FeederMetricRequest,
    ): Observable<SimpleMultiSeries<string>>;
}
