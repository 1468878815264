import {Observable} from 'rxjs';
import {PagingResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {OutputMeterDto} from '@core/interfaces/engin/load-profile-analysis/output-meter';
import {InfrastructureType} from '@core/interfaces/engin/load-profile-analysis/lp-common.model';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

export interface LpBatchDto {
    id: number;
    batchNumber: number;
    startDate: Date;
    endDate: Date;
    executedDate: Date;
    batchStatus: BatchStatusType;
    uploaded: boolean;
}

export enum BatchStatusType {
    NOT_RUN,
    SUCCESSFUL,
    FAILED,
}

export interface FeederReviewDto {
    feederName: string;
    feederId: string;
    station: string;
    region: string;
    completenessActual: number;
    completenessExpected: number;
    quality: boolean;
    analysis: boolean;
    statusDataCompleteness: LpAnalysisStatusType;
    statusDataQuality: LpAnalysisStatusType;
    statusAnalysis: LpAnalysisStatusType;
    reviewStatus: LpReviewStatusType;
    assignedUserId: string;
    assignedUserName: string;
    reviewerId: string;
    reviewerName: string;
    peek: number;
    meters: OutputMeterDto[];
}

export enum LpAnalysisStatusType {
    NOT_RUN = 'NOT_RUN',
    IN_PROGRESS = 'IN_PROGRESS',
    PASS = 'PASS',
    FAIL = 'FAIL',
}

export const LpAnalysisStatusTypeLabel = {
    [LpAnalysisStatusType.NOT_RUN]: 'Not Run',
    [LpAnalysisStatusType.IN_PROGRESS]: 'In Progress',
    [LpAnalysisStatusType.PASS]: 'Pass',
    [LpAnalysisStatusType.FAIL]: 'Fail',
};

export enum LpReviewStatusType {
    ERROR_COMPLETENESS = 'ERROR_COMPLETENESS',
    ERROR_QUALITY = 'ERROR_QUALITY',
    ERROR_ANALYSIS = 'ERROR_ANALYSIS',
    PENDING_REVIEW = 'PENDING_REVIEW',
    APPROVED = 'APPROVED',
}

export const LpReviewStatusTypeLabel = {
    [LpReviewStatusType.PENDING_REVIEW]: 'Pending review',
    [LpReviewStatusType.APPROVED]: 'Approved',
    [LpReviewStatusType.ERROR_COMPLETENESS]: 'Completeness Error',
    [LpReviewStatusType.ERROR_QUALITY]: 'Quality Error',
    [LpReviewStatusType.ERROR_ANALYSIS]: 'Analysis Error',
};

export interface FeederReviewSummaryDto {
    approvedPercent: number;
    unreviewedPercent: number;
    errorAnalysisPercent: number;
    errorQualityPercent: number;
    errorCompletenessPercent: number;
}

export interface FeederApprovalDto {
    feederIds: string[];
    assignedUserName: string;
    assignedUserId: string;
}

export interface LpFeederReviewDto {
    id: number;
    feederId: string;
    batchNumber: number;
    reviewStatus: LpReviewStatusType;
    assignedUserId: string;
    assignedUserName: string;
    reviewedByUserId: string;
    reviewedByUserName: string;
    reviewedOn: Date;
}

export interface AssignMeterParentRequest {
    infrastructureType: InfrastructureType;
    infrastructureId: string;
    selectedMeterIds: string[];
}

export interface FeederApproveRequest {
    userId: string;
    userName: string;
}

export interface FeederDeleteRequest {
    feederIds: string[];
}

export interface FeederRenameRequest {
    newFeederName: string;
}

export interface ChangeHistoryDto {
    changeDate: Date;
    changeDoneByName: string;
    rows: string[];
}

export type FeederMetadata = {[key: string]: FeederMetadataItem};

export type FeederMetadataItem = {label: string; value: string};

export abstract class FeederReviewLPService {
    abstract getBatchesList(): Observable<PagingResponse<LpBatchDto>>;

    abstract getFeedersToReviewList(batchNumber: number): Observable<DataSource>;
    abstract downloadFeedersToReviewList(batchNumber: number, paramsString?: string): Observable<DownloadCSVUrl>;

    abstract getFeedersSummary(batchNumber: number): Observable<FeederReviewSummaryDto>;
    abstract getFeederChangeHistory(batchNumber: number, feederId: string): Observable<ChangeHistoryDto[]>;
    abstract getFeederMetadataByBatch(feederId: string): Observable<FeederMetadata>;

    abstract setReviewer(batchNumber: number, data: FeederApprovalDto): Observable<LpFeederReviewDto>;
    abstract changeFeederMetersByBatch(batchNumber: number, data: AssignMeterParentRequest): Observable<any>;
    abstract deleteFeeders(batchNumber: number, data: FeederDeleteRequest): Observable<any>;
    abstract renameFeeder(feederId: string, data: FeederRenameRequest): Observable<any>;

    abstract processEntireBatch(batchNumber: number): Observable<DownloadURL>;

    abstract approveFeeder(batchNumber: number, feederId: string, data: FeederApproveRequest): Observable<any>;
    abstract correctFeederByBatch(batchNumber: number, feederId: string, data: FeederApproveRequest): Observable<any>;
}
