import {Pipe, PipeTransform} from '@angular/core';
import {FormatsService} from '../../@core/utils';

@Pipe({
    name: 'unitPercent',
})
export class UnitPercentPipe implements PipeTransform {
    transform(value: any, decimals: number = 2) {
        return value ? `${FormatsService.preparePercentageLabelFromDecimal(value, decimals)}` : '';
    }
}
