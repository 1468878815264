import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {
    AssetClassConfig,
    DatasetItem,
    TenantUnitsConfig,
    WorkflowConfig,
    EnginDatatableDetails,
    ConfigLoadProfileRendering,
} from '@core/interfaces/common/configuration';
import {APIResponse} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';

@Injectable()
export class ConfigurationApi {
    private readonly prefix = 'configuration';

    constructor(private api: HttpService) {}

    getWorkFlows(): Observable<APIResponse<WorkflowConfig[]>> {
        return this.api.get(`${this.prefix}/workflow`, {});
    }

    getDatasets(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.get(`${this.prefix}/dataset`);
    }

    getTablesFromSchema(schemaName: string): Observable<APIResponse<EnginDatatableDetails[]>> {
        return this.api.get(`${this.prefix}/schema-table/${schemaName}`);
    }

    getAssetClassCodes(): Observable<APIResponse<AssetClassConfig[]>> {
        return this.api.get(`${this.prefix}/asset-class-codes`);
    }

    getTenantUnits(): Observable<APIResponse<TenantUnitsConfig>> {
        return this.api.get(`${this.prefix}/units`);
    }

    getPersistedDatasetList(): Observable<APIResponse<DatasetItem[]>> {
        return this.api.get(`${this.prefix}/persisted/datasets`);
    }

    getPersistedTablesFromDataset(schemaName: string): Observable<APIResponse<EnginDatatableDetails[]>> {
        return this.api.get(`${this.prefix}/persisted/tables/${schemaName}`);
    }

    getPersistedDatasetTableAllList(): Observable<APIResponse<EnginDatatableDetails[]>> {
        return this.api.get(`${this.prefix}/persisted/alltables`);
    }

    getConfigLoadProfileRendering(): Observable<ConfigLoadProfileRendering> {
        return this.api.get(`${this.prefix}/lp/rendering`).pipe(map((res: APIResponse<any>) => res.response));
    }
}
